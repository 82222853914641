import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import TicketsRequests from "./TicketsRequests";
import { toast, ToastContainer } from 'react-toastify';
import CheckOutForm from './_CheckOutForm'
import TransactionExpired from './_TransactionExpired'
import WaitingPayment from './_WaitingPayment'
import DetailsForm from "./_DetailsForm";
import CountdownTimer from './_CountdownTimer'
import { SelectCountries, Input, Checkbox } from '../../Layout/Forms/Form';
import ReCAPTCHA from 'react-google-recaptcha';
import BottomSheetModal from "../../Layout/BottomSheetModal/BottomSheetModal";

const TicketTransaction = () => {

    const [isLoading, setIsLoading] = useState(true);
    const urlParams = useParams();
    const [isVerified, setIsVerified] = useState(false);
    const refCaptcha = useRef(null);
    const navigate = useNavigate();

    const [state, setState] = useState({
        transaction: {},
        form: null
    })

    const [modalVenueTermsConditions, setModalVenueTermsConditions] = useState({
        isVisible: false,
    })

    const [contentBoxViewer, setContentBoxViewer] = useState(null)


    const getInfoEventTicket = async (showLoading = true) => {
        if (showLoading) {
            setIsLoading(true)
        }
        let response = await TicketsRequests.getInfoEventTransaction(urlParams);
        if (response && response.status == true) {
            setState({
                ...state,
                countryList: response.data.countryList,
                event: response.data.event,
                tickets: response.data.tickets,
                transaction: response.data.transaction,
                form: {
                    name: '',
                    phone_country: '+351',
                    phone_number: '',
                    email: '',
                    contribuinte: '',
                    payment_method: 'MBWAY',
                    terms:0
                }
            })
        } else {
            let msg = response.msg ? response.msg : "Ocorreu um erro interno, tente mais tarde.";
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
        setIsLoading(false)
    }

    useEffect(() => {
        getInfoEventTicket()
    }, [])

    useEffect(() => {
        if (state.transaction && state.transaction.status == "PENDING") {
            setContentBoxViewer("CheckOutForm")
        } else if (state.transaction && state.transaction.status == "PENDING PAYMENT") {
            setContentBoxViewer("WaitingPayment")
        } else if (state.transaction && state.transaction.status == "COMPLETED") {
            setContentBoxViewer("DetailsForm")
        } else if (state.transaction && state.transaction.status == "CANCELED") {
            setContentBoxViewer("TransactionExpired")
        }
    }, [state.form])

    const handleToastError = (msg) => {
        toast.error(msg, {
            position: "top-right",
            autoClose: 15000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        })
    }

    const ContentBox = () => {
        return (
            <>
                {/* <CheckOutForm
                    show={contentBoxViewer == "CheckOutForm" ? true : false}
                    params={state}
                    setIsLoading={(param) => setIsLoading(param)}
                    isLoading={isLoading}
                    handlerEndTime={() => setContentBoxViewer("TransactionExpired")}
                    handlerFinishWithSucessUpdateMethodPayment={() => getInfoEventTicket()}
                    handlerToastError={(msg) => handleToastError(msg)}
                /> */}
                <TransactionExpired
                    show={contentBoxViewer == "TransactionExpired" ? true : false}
                />
                <WaitingPayment
                    show={contentBoxViewer == "WaitingPayment" ? true : false}
                    handlerRefresh={() => getInfoEventTicket(false)}
                />
                <DetailsForm
                    show={contentBoxViewer == "DetailsForm" ? true : false}
                    params={state}
                />
            </>)

    }






    const seriaizeCountryList = () => {
        if (state.countryList && state.countryList.length > 0)
            return state.countryList.map((item) => {
                return {
                    label: item.name,
                    value: item.phone_country,
                    icon: item.icon
                }
            });
    }

    const handleChangeInputs = (e) => {

        if (!isLoading) {
            let oldStateErrors = state.errors ? state.errors : {};
            // console.log(Object.hasOwn(oldStateErrors, [e.target.name]))
            // if(typeof oldStateErrors[e.target.name] !== "undefined"){
                delete  oldStateErrors[e.target.name]
            // }
            setState({
                ...state,
                form: {
                    ...state.form,
                    [e.target.name]: e.target.value
                },
                errors: oldStateErrors
            });
        }
    }
    const handleRecaptchaChange = (token) => {
        setIsVerified(true);
        handleChangeInputs({
            target: {
                name: "recaptcha",
                value: token
            }
        })
    };

    const handlerSubmitTicketPurchase = async (e) => {
        e.preventDefault();
        let errorArray = {};
        let error = false;
        
        if(!state.form.terms){
            toast.error("Aceite os termos e condições antes de avançar.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return false;
        }

        if (state.form.phone_number.length == 0) {
            errorArray = {
                ...errorArray,
                ...{
                    phone_number: [
                        'Preenche o Nº Telemóvel'
                    ]
                }
            };
            error = true;
        }

        if (state.form.email.length == 0) {
            errorArray = {
                ...errorArray,
                ...{
                    email: [
                        'Campo obrigatório'
                    ]
                }
            };
            error = true;
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(state.form.email)) {
                errorArray = {
                    ...errorArray,
                    ...{
                        email: [
                            ...(errorArray.email || []),
                            'Email inválido'
                        ]
                    }
                };
                error = true;
            }
        }

        if (state.form.name.length == 0) {
            errorArray = {
                ...errorArray,
                ...{
                    name: [
                        'Campo obrigatório'
                    ]
                }
            };
            error = true;
        }

        if (error) {
            toast.error("Verifique os erros apresentados no formulário", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });

            setState({
                ...state,
                errors: errorArray
            });
            // setIsLoading(false);
            return false;
        }



        // Chamar o reCAPTCHA para obter o token de verificação
        //const recaptchaValue = refCaptcha.current.getValue();
        const recaptchaValue = await refCaptcha.current.executeAsync();
        if (recaptchaValue) {
            await submitForm(recaptchaValue);
        } else {
            toast.error("Verifique o reCAPTCHA antes de submeter o formulário.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }

    const submitForm = async (recaptchaValue) => {
        setIsLoading(true);
        const formWithToken = { ...state.form, recaptcha: recaptchaValue }
        let response = await TicketsRequests.submitPurchaseTicket({ ...urlParams, ...formWithToken });
        if (response) {
            if (response.status == true) {
                getInfoEventTicket()
            } else {
                if(refCaptcha && refCaptcha.current){
                    await refCaptcha.current.reset()
                }
                //alert("error")
                let msgError = "Ocorreu um erro interno, contacto info@nightspot.pt ou tente mais tarde.";
                if (response.msg && response.msg.length > 0) {
                    msgError = response.msg;
                } else if (response.error && response.error.length > 0) {
                    msgError = response.error;
                }
                // setState({
                //     ...state,
                //     errors: {
                //         contribuinte: [
                //             msgError
                //         ]
                //     }
                // })
                console.log(msgError)
                toast.error(msgError, {
                    position: "top-right",
                    autoClose: 15000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setIsLoading(false)
                return false;
            }

        } else {
            if (response.msg && response.msg.length > 0) {

                toast.error(response.msg, {
                    position: "top-right",
                    autoClose: 15000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        }
        setIsLoading(false)
    }

    const ModalVenueTermsConditions = () => {
        return (
            <BottomSheetModal 
            isVisible={modalVenueTermsConditions.isVisible} 
            onClose={() => 
                setModalVenueTermsConditions({
                    ...modalVenueTermsConditions,
                    isVisible: false
                })
            } 
            height="75vh">
                <div style={{ padding: '20px' }}>
                    <div className="row mb-2">
                        <div className="col-12 text-center">
                            <h4>Termos & Condições {state.event.venue_name}</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center" dangerouslySetInnerHTML={{__html: state.event.venue_terms && state.event.venue_terms.length > 0 ? state.event.venue_terms : 'Sem informações'}}>
                            
                       
                        </div>
                    </div>
                </div>

            </BottomSheetModal>
        )
    }

    return (
        isLoading ? <Loading /> : (
            <div className="container-fluid">
                <div className="position-absolute opacity-25 dark:opacity-25" style={{ minHeight: '100vh', background: `url('${state.event.image}') center center / cover`, left: '-30%', top: '-30%', width: '100%', filter: 'blur(100px)' }}>
                </div>
                <div className="container position-relative z-1">
                    <div className="box-tables mt-5">
                        <div className="row">
                            <div className="col-12 col-sm-3">
                                <div className="row">
                                    <div className="col-12 ">
                                        <img src={state.event.image} className="img-fluid img-event-single" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-9">
                                <h1>{state.event.name}</h1>
                                <div className="row mt-3 mt-sm-0 ">
                                    <div className="col-12">
                                        {state.event.date_formated}
                                        <small className="mx-3" style={{ color: 'rgb(234, 44, 97)' }}>|</small>
                                        <span style={{ color: '#939393' }}>{state.event.hour_start} - {state.event.hour_end}</span>
                                    </div>
                                </div>

                                <ContentBox />
                                {contentBoxViewer == "CheckOutForm" ? (
                                    <>

                                        <div className="row mt-3 position-relative z-2" >
                                            <div className="col-12">
                                                <div className="box-single-table noclick fs-5" style={{ borderWidth: 0 }}>
                                                    Têm <CountdownTimer endDate={state.transaction.transaction_date_create} handlerEndTime={() => setContentBoxViewer("TransactionExpired")} /> para finalizar a sua compra
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3 position-relative z-2" >
                                            <div className="col-12">
                                                <div className="box-single-table noclick" style={{ borderWidth: 0 }}>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h3>Check-out</h3>
                                                        </div>
                                                    </div>
                                                    <form onSubmit={handlerSubmitTicketPurchase} >
                                                        <div className="row">
                                                            <div className=" col-12 col-sm-7 ">
                                                                <label className="form-label">Dados da compra</label>
                                                                <div className="row">
                                                                    <div className="col-12 ">
                                                                        <Input
                                                                            label="Nome"
                                                                            name="name"
                                                                            type="text"
                                                                            value={state.form.name}
                                                                            onChange={(e) => handleChangeInputs(e)}
                                                                            onPaste={(e) => {
                                                                                e.preventDefault();
                                                                                handleChangeInputs({
                                                                                    target: {
                                                                                        name: e.target.name,
                                                                                        value: e.clipboardData.getData("Text"),
                                                                                    },
                                                                                });
                                                                            }}
                                                                            errors={state.errors}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-12 d-grid  mx-auto ">

                                                                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>

                                                                            <SelectCountries
                                                                                label="&nbsp;"
                                                                                name="phone_country"
                                                                                value={state.form.phone_country}
                                                                                onChange={(e) => handleChangeInputs(e)}
                                                                                options={seriaizeCountryList()}
                                                                                errors={state.errors}

                                                                            />
                                                                            <div className="ms-1 w-100">
                                                                                <Input
                                                                                    label="Nº Telemóvel"
                                                                                    name="phone_number"
                                                                                    type="number"
                                                                                    value={state.form.phone_number}
                                                                                    onChange={(e) => handleChangeInputs(e)}
                                                                                    onPaste={(e) => {
                                                                                        e.preventDefault();
                                                                                        handleChangeInputs({
                                                                                            target: {
                                                                                                name: e.target.name,
                                                                                                value: e.clipboardData.getData("Text"),
                                                                                            },
                                                                                        });
                                                                                    }}
                                                                                    errors={state.errors}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className="row">
                                                                    <div className="col-12 ">
                                                                        <Input
                                                                            label="Email"
                                                                            name="email"
                                                                            type="text"
                                                                            value={state.form.email}
                                                                            onChange={(e) => handleChangeInputs(e)}
                                                                            onPaste={(e) => {
                                                                                e.preventDefault();
                                                                                handleChangeInputs({
                                                                                    target: {
                                                                                        name: e.target.name,
                                                                                        value: e.clipboardData.getData("Text"),
                                                                                    },
                                                                                });
                                                                            }}
                                                                            errors={state.errors}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-12 ">
                                                                        <Input
                                                                            label="Contribuinte"
                                                                            name="contribuinte"
                                                                            type="text"
                                                                            value={state.form.contribuinte}
                                                                            onChange={(e) => handleChangeInputs(e)}
                                                                            onPaste={(e) => {
                                                                                e.preventDefault();
                                                                                handleChangeInputs({
                                                                                    target: {
                                                                                        name: e.target.name,
                                                                                        value: e.clipboardData.getData("Text"),
                                                                                    },
                                                                                });
                                                                            }}
                                                                            errors={state.errors}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="row" style={{ marginTop: '30px' }}>
                                                                    <div className="col-12">
                                                                        Método pagamento
                                                                        <div className="list-group list-group-radio d-grid gap-2 border-0">
                                                                            <div className="position-relative">
                                                                                <input style={{ zIndex: 999, top: '35%' }} className="form-check-input position-absolute  end-0 me-3 fs-5" type="radio" name="listGroupRadioGrid" id="paumento_method_mbway" value="" onChange={() => false} checked={'checked'} />
                                                                                <label className="list-group-item py-3 pe-5" htmlFor="paumento_method_mbway" style={{ backgroundColor: '#0a1116', color: '#dee2e6' }}>
                                                                                    <strong className="fw-semibold">
                                                                                        <img src="/img/payments/mbway.png" className="img-fluid" style={{ height: '32px', width: 'auto' }} />
                                                                                    </strong>
                                                                                    <span className="mt-1 d-block d-none small opacity-75">Este método de pagamento têm um valor máximo de <u>750€</u> por transferência imposto pela SIBS.</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>




                                                                <div className="row" style={{ marginTop: '30px' }}>
                                                                    <div className="col-12">
                                                                        <ReCAPTCHA size="invisible" ref={refCaptcha} theme="dark" sitekey="6LeJQrwpAAAAAHSzvB8Z_eA8SBZk9YBOBwBU6qyz" onChange={handleRecaptchaChange} />

                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div className="col-12 col-sm-5 ">
                                                                <h3>Resumo</h3>
                                                                <table className="table table-dark table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <td>Descrição</td>
                                                                            <td>Valor</td>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                        <tr style={{ fontSize: '10px' }}>
                                                                            <td>Bilhetes</td>
                                                                            <td>{state.transaction.transaction_price_ticket}€</td>
                                                                        </tr>
                                                                        <tr style={{ fontSize: '10px' }}>
                                                                            <td>Custo operação</td>
                                                                            <td>{state.transaction.transaction_fee}€</td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td>Total</td>
                                                                            <td>{state.transaction.transaction_price_total}€</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}>
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <Checkbox
                                                                                        label=""
                                                                                        name="terms"
                                                                                        value={[state.form.terms]}
                                                                                        valueCK={true}
                                                                                        checkBoxChange={(e) => setState({
                                                                                            ...state,
                                                                                            form:{
                                                                                                ...state.form,
                                                                                                terms: !state.form.terms
                                                                                            }
                                                                                        })}
                                                                                        errors={state.form.errors}
                                                                                    /> 
                                                                                    <div>Aceito os Termos e Condições da plataforma e da organização do evento <a className="link-light" onClick={() => setModalVenueTermsConditions({
                                                                                        ...modalVenueTermsConditions,
                                                                                        isVisible: true
                                                                                    })}>{state.event.venue_name}</a></div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan="2">
                                                                                <button type="submit" className="btn btn-primary d-block w-100" >Confirmar compra<i className="fa-solid fa-arrow-right ms-2"></i></button>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>

                                            </div>
                                        </div>
                                    </>
                                ) : null}

                            </div>
                        </div>
                    </div>

                </div>
                <ModalVenueTermsConditions

                />
                <ToastContainer />
            </div>
        )
    )
}

export default TicketTransaction;